import { Button, Loader, Page } from "@wix/design-system";
import { Settings, Reviews } from "@wix/wix-ui-icons-common";
import "@wix/design-system/styles.global.css";
import React, { useEffect, useState } from "react";
import { useAPI } from "../api";
import { Application, SiteInformation } from "../types";
import { SetSiteKeyModal } from "../components/set-site-key-modal";
import { EnterSiteKey } from "../components/enter-site-key";
import { IntegrationList } from "../components/integration-list";
import { useNavigateWithQuery } from "../hooks/use-navigate-with-query";
import { redirectBlank } from "../helpers/redirect-blank";

export function DashboardPage() {
  const navigate = useNavigateWithQuery();
  const api = useAPI();
  const [information, setInformation] = useState<SiteInformation | null>(null);
  const [application, setApplication] = useState<Application | null>(null);
  const [addSiteKey, setAddSiteKey] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get<SiteInformation>("information");
        setInformation(data);
      } catch {
        //
      }
    })();
  }, [api]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get<Application>("application");
        setApplication(data);
      } catch {
        //
      }
    })();
  }, [api]);

  return (
    <React.Fragment>
      <Page>
        <Page.Header
          title="Integrations"
          subtitle="View and monitor your integrations."
          actionsBar={
            <div style={{ display: "flex", columnGap: "20px" }}>
              <Button
                prefixIcon={<Reviews />}
                skin="light"
                onClick={() => {
                  redirectBlank(
                    `https://www.wix.com/app-market/add-review/${application?.app_id}`
                  );
                }}
              >
                Add review
              </Button>
              <Button
                prefixIcon={<Settings />}
                onClick={() => {
                  navigate("/dashboard/settings");
                }}
              >
                Settings
              </Button>
            </div>
          }
        />
        <Page.Content>
          {!information ? (
            <Loader />
          ) : information.site_key ? (
            <IntegrationList />
          ) : (
            <EnterSiteKey
              onEnter={() => {
                setAddSiteKey(true);
              }}
            />
          )}
        </Page.Content>
      </Page>
      <SetSiteKeyModal
        show={addSiteKey}
        onClose={(information?: SiteInformation) => {
          setAddSiteKey(false);
          if (information) {
            setInformation(information);
          }
        }}
      />
    </React.Fragment>
  );
}
