import { Page, Card, Loader, Button, Text } from "@wix/design-system";
import React, { useEffect, useState } from "react";
import { useAPI } from "../api";
import { Application, SiteInformation } from "../types";
import { EnterSiteKey } from "../components/enter-site-key";
import { ChangeSiteKey } from "../components/change-site-key";
import { SetSiteKeyModal } from "../components/set-site-key-modal";
import { useNavigateWithQuery } from "../hooks/use-navigate-with-query";
import { PaidPlans } from "@wix/wix-ui-icons-common";
import { redirectBlank } from "../helpers/redirect-blank";

interface Instance {
  billing: {
    plan: string | null;
  };
}

export function SettingsPage() {
  const navigate = useNavigateWithQuery();
  const api = useAPI();
  const [setSiteKey, setSetSiteKey] = useState<boolean>(false);
  const [information, setInformation] = useState<SiteInformation | null>(null);
  const [application, setApplication] = useState<Application | null>(null);
  const [instance, setInstance] = useState<Instance | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get<SiteInformation>("information");
        setInformation(data);
      } catch {
        //
      }
    })();
  }, [api]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get<Application>("application");
        setApplication(data);
      } catch {
        //
      }
    })();
  }, [api]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get<Instance>("instance");
        setInstance(data);
      } catch {
        //
      }
    })();
  }, [api]);

  return (
    <React.Fragment>
      <Page>
        <Page.Header
          title="Settings"
          subtitle="Control the settings of the integrations."
          showBackButton
          onBackClicked={() => {
            navigate("/dashboard");
          }}
        />
        <Page.Content>
          <Card>
            <Card.Header title="Site key" />
            <Card.Divider />
            <Card.Content>
              {information ? (
                information?.site_key ? (
                  <ChangeSiteKey
                    onEnter={() => {
                      setSetSiteKey(true);
                    }}
                  />
                ) : (
                  <EnterSiteKey
                    onEnter={() => {
                      setSetSiteKey(true);
                    }}
                  />
                )
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Loader />
                </div>
              )}
            </Card.Content>
          </Card>
          <Card>
            <Card.Header title="Plans" />
            <Card.Divider />
            <Card.Content>
              {instance?.billing.plan ? (
                <Text>
                  Currently active plan is <b>{instance.billing.plan}</b>.
                </Text>
              ) : (
                <>
                  <Text>Currently, there are no plans activated.</Text>
                  <br />
                  <br />
                  <Button
                    prefixIcon={<PaidPlans />}
                    onClick={() => {
                      redirectBlank(
                        `https://manage.wix.com/upgrade/app/${application?.app_id}/plan?appInstanceId=${information?.instance_id}`
                      );
                    }}
                  >
                    Activate plan
                  </Button>
                </>
              )}
            </Card.Content>
          </Card>
        </Page.Content>
      </Page>
      <SetSiteKeyModal
        show={setSiteKey}
        siteKey={information?.site_key}
        onClose={(information?: SiteInformation) => {
          setSetSiteKey(false);
          if (information) {
            setInformation(information);
          }
        }}
      />
    </React.Fragment>
  );
}
