import { EmptyState, TextButton } from "@wix/design-system";
import { Add } from "@wix/wix-ui-icons-common";

export function EnterSiteKey({ onEnter }: { onEnter: () => void }) {
  return (
    <EmptyState
      title="Enter your site key"
      subtitle="Enter your side key provided by us. To get a site key, send us an email at info@integracije.si."
      theme="page"
    >
      <TextButton
        prefixIcon={<Add />}
        onClick={() => {
          onEnter();
        }}
      >
        Enter site key
      </TextButton>
    </EmptyState>
  );
}
