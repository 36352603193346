import {
  FormField,
  Input,
  MessageModalLayout,
  Modal,
} from "@wix/design-system";
import { useEffect, useState } from "react";
import { useAPI } from "../api";
import { SiteInformation } from "../types";

export function SetSiteKeyModal({
  show,
  siteKey: providedSiteKey,
  onClose,
}: {
  show: boolean;
  siteKey?: string | null;
  onClose: (data?: SiteInformation) => void;
}) {
  const [siteKey, setSiteKey] = useState<string | null>(
    providedSiteKey || null
  );
  const api = useAPI();

  useEffect(() => {
    setSiteKey(providedSiteKey || null);
  }, [providedSiteKey]);

  return (
    <Modal
      isOpen={show}
      onRequestClose={() => {
        onClose();
      }}
    >
      <MessageModalLayout
        primaryButtonText={"Save"}
        primaryButtonProps={{
          disabled: !siteKey?.length,
        }}
        secondaryButtonText={"Cancel"}
        primaryButtonOnClick={async () => {
          try {
            const { data } = await api.post("information", {
              site_key: siteKey,
            });

            onClose(data);
          } catch {
            //
          }
        }}
        secondaryButtonOnClick={() => {
          onClose();
        }}
        title={"Enter site key"}
      >
        <FormField label="Site key" labelPlacement="top">
          <Input
            value={siteKey || ""}
            onChange={(event) => {
              setSiteKey(event.target.value);
            }}
          />
        </FormField>
      </MessageModalLayout>
    </Modal>
  );
}
