import { EmptyState, TextButton } from "@wix/design-system";
import { Edit } from "@wix/wix-ui-icons-common";

export function ChangeSiteKey({ onEnter }: { onEnter: () => void }) {
  return (
    <EmptyState
      title="Your site key is entered"
      subtitle="Now you can track you integrations."
      theme="page"
    >
      <TextButton
        prefixIcon={<Edit />}
        onClick={() => {
          onEnter();
        }}
      >
        Change site key
      </TextButton>
    </EmptyState>
  );
}
