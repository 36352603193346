import { NavigateOptions, useLocation, useNavigate } from "react-router-dom";

export function useNavigateWithQuery() {
  const navigate = useNavigate();
  const location = useLocation();

  return (to: string, options?: NavigateOptions) => {
    navigate(
      {
        pathname: to,
        search: location.search,
      },
      options
    );
  };
}
