
import {
  WixDesignSystemProvider,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { Routes, Route } from "react-router-dom";
import { NotFoundPage } from "./pages/not-found-page";
import { DashboardPage } from "./pages/dashboard-page";
import { SettingsPage } from "./pages/settings-page";
import { Layout } from "./Layout";

function App() {
  return (
    <WixDesignSystemProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="dashboard/settings" element={<SettingsPage />} />
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </WixDesignSystemProvider>
  );
}

export default App;
