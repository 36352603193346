import axios from "axios";
import { useQuery } from "./hooks/use-query";

const api = axios.create({
  baseURL: "/api/",
});

export function useAPI() {
  const query = useQuery();

  api.defaults.headers.common["X-Instance"] = query.get("instance");

  return api;
}
