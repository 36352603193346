import { Badge, Table, Text } from "@wix/design-system";
import { Integration } from "../types";
import { useAPI } from "../api";
import { useEffect, useState } from "react";

const columns = [
  {
    title: "Name",
    render: (row: Integration) => (
      <div>
        <Text>{row.name}</Text>
        <br />
        <Text secondary>{row.description}</Text>
      </div>
    ),
  },
  {
    title: "Status",
    render: (row: Integration) => <Status status={row.status} />,
  },
  {
    title: "Details",
    render: (row: Integration) => row.log_details,
  },
];

function Status({ status }: { status: Integration["status"] }) {
  switch (status) {
    case "INACTIVE":
      return (
        <Badge size="small" skin="warning">
          Inactive
        </Badge>
      );
    case "ACTIVE":
      return (
        <Badge size="small" skin="success">
          Active
        </Badge>
      );
  }
}

export function IntegrationList() {
  const api = useAPI();
  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const [invalidSiteKey, setInvalidSiteKey] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get<Integration[] | null>("integrations");
        if (Array.isArray(data)) {
          setIntegrations(data);
        } else {
          // invalid site key
          setInvalidSiteKey(true);
        }
      } catch {
        //
      }
    })();
  }, [api]);

  return invalidSiteKey ? (
    <Text skin="error">
      Entered site key is invalid. Update the site key in settings.
    </Text>
  ) : (
    <Table skin="standard" data={integrations} columns={columns}>
      <Table.Content />
    </Table>
  );
}
